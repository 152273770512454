import React, { useEffect } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Link from './components/Link';
import './App.css';
import items from './config/items';
import playlist from './config/playlist';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";

function App() {
  useEffect(() => {
    document.title = 'Arne Van Daele - Techno Producer & Software Engineer';
  }, []);
  return (
    <div className='bg-gradient-to-tr from-indigo-500 to-indigo-900'>
      <Header />
        <div className='px-8 mb-8'>
            <p class='text-white text-sm uppercase mb-2'>My Socials</p>
            <div className='flex flex-col space-y-2'>
                {items.map((item, index) => (
                    <Link key={index} item={item} />
                ))}
            </div>
        </div>

        <div className='px-8'>
            <p className='text-white text-sm uppercase mb-2'>My Spotify playlists</p>
            <div className='flex flex-col space-y-2'>
                {playlist.map((item, index) => (
                    <Link key={index} item={item} />
                ))}
            </div>
        </div>

      <Footer />
    </div>
  );
}

export default App;
